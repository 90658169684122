@import "./variables.scss";

#join {
  width: 100%;

  .title_Background {
    height: calc(100vh - 8rem);
    background: url(../asset/images/join-us/Resource_integration.png);
    background-size: 100% 100%;

    @media screen and (max-width: 700px) {
      height: 260px;
    }

    .title {
      font-size: 2rem;
      padding: 200px;
      font-weight: bolder;

      @media screen and (max-width: 700px) {
        padding: 50px 0;
      }

      color: black;
      font-family: "HarmonyOS_Thin", "Gill Sans", sans-serif;
      text-align: center;
    }

    .title_line {
      width: 240px;
      height: 2px;
      background: #000;
      margin: 0 auto;
    }
  }

  .main {
    position: relative;
    margin-bottom: 40px;
    font-size: 1.3rem;
    padding: 2rem 0;
    line-height: 1.5;
    width: 100%;
    min-height: 400px;

    .head_wraps {
      width: 1280px;
      margin: 0 auto;
      text-align: center;

      @media screen and (max-width: 700px) {
        width: 90%;
      }

      .search {
        width: 30%;
        margin-bottom: 40px;

        @media screen and (max-width: 700px) {
          width: 80%;
        }
      }
    }
  }

  .search_error {
    font-size: 20px;
    margin: 0 auto;
    font-family: "HarmonyOS_light", "Gill Sans", sans-serif;
    margin-top: 30px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
  }

  .Cards {
    user-select: none;
    width: 100%;

    &:hover {
      box-shadow: 0 0 3px 1px rgba(128, 128, 128, 0.267);
      transform: scale(1.05, 1.05);
    }
  }
}
