@import "./variables.scss";
.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@each $start, $end, $time, $index in (-200px, 0, 1s, 1), (-200px, 0, 1s, 2),
  (-180px, 0, 1s, 3), (-180px, 0, 1s, 4), (180px, 0, 1s, 5)
{
  .slide_horizontal_#{$index} {
    animation: slide-horizontal_#{$index}
      #{$time}
      cubic-bezier(0, 0, 0.58, 1)
      both;

    @if $index == 3 {
      animation-delay: 0.4s;
    }
  }

  @keyframes slide-horizontal_#{$index} {
    0% {
      transform: translateX(#{$start});
      opacity: 0;
    }

    100% {
      transform: translateX(#{$end});
      opacity: 1;
    }
  }
}

@each $start, $end, $time, $index in (-60px, 80px, 1s, 1), (180px, 0, 1s, 2),
  (-200px, 0, 1s, 3)
{
  .slide_vertical_#{$index} {
    animation: slide-vertical_#{$index}
      #{$time}
      cubic-bezier(0, 0, 0.58, 1)
      both;

    @if $index == 3 {
      animation-delay: 0.4s;
    }
  }

  @keyframes slide-vertical_#{$index} {
    0% {
      transform: translateY(#{$start});
      opacity: 0;
    }

    100% {
      transform: translateY(#{$end});
      opacity: 1;
    }
  }
}

#homepage {
  background-color: rgb(250, 250, 250);

  .main {
    width: 100%;
    background: url(../asset/images/home/home-page-banner.jpg) 100% 100%;
    background-size: cover;
    height: calc(100vh - 8rem);
    text-align: center;

    @include flex(center, center);

    @media screen and (max-width: 700px) {
      height: calc(100vh - 20rem);
    }

    .shadow {
      @include flex(center, center);

      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;

      .video {
        width: 50%;
        height: 60%;
        object-fit: fill;

        @media screen and (max-width: 700px) {
          width: 80%;
          height: 30%;
        }
      }

      .closeIcon {
        padding: 1rem;
        display: block;
        font-size: 2rem;
        color: rgba(#fff, 0.8);

        &:hover {
          color: rgba(rgb(62, 155, 209), 0.8);
        }
      }

      background-color: rgba(map-get($map: $grayRange, $key: "9"), 0.6);
    }

    h1 {
      font-family: "HarmonyOS_Sans_SC_Black", "sans";
      font-weight: bolder;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      color: #465a6b;
    }

    .main_des {
      font-size: 0.7rem;
      color: #524c4c;
    }

    .PlayICon {
      width: 3.5rem;
      height: 3.5rem;
      cursor: pointer;
    }
  }

  .space {
    background-color: #fff;
    height: 0.5rem;
  }

  .total_num {
    height: 360px;
    margin-top: 40px;
    background: url(../asset//images/home/The_city_building.png);
    background-size: 100% 100%;
    position: relative;

    @media screen and (max-width: 700px) {
      height: 200px;
      margin-top: 20px;
    }

    .num_content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1280px;

      @media screen and (max-width: 700px) {
        width: 90%;
      }

      text-align: center;
      transform: translate(-50%, -50%);
      color: white;
    }

    .num_mark {
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 2px;
      margin: 10px 0;
    }
  }

  .aboutUs {
    width: 1280px;
    margin: 0 auto;

    @media screen and (max-width: 700px) {
      width: 90%;
    }

    .cardContainer {
      display: flex;
      justify-content: space-around;
      padding: 60px 0;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
    }

    .aboutUsCard_content {
      padding: 60px 0;

      > div:nth-of-type(1) {
        font-size: 1rem;
        font-weight: bold;
      }

      > div:nth-of-type(2) {
        font-size: 0.55rem;
      }
    }

    .aboutUsCard_content_1 {
      margin-top: 280px;

      @media screen and (max-width: 700px) {
        margin-top: 220px;
      }

      > div:nth-of-type(1) {
        font-size: 1rem;
        font-weight: bold;
      }

      > div:nth-of-type(2) {
        font-size: 0.55rem;
      }
    }

    @each $name, $index in (bh, 1), (ls, 3), (te, 2) {
      .aboutUsCard_#{$index} {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        height: 420px;
        width: 320px;
        position: relative;
        background-image: url("../asset/images/home/#{$name}.png");
        background-size: 100% 100%;
        text-align: center;

        @media screen and (max-width: 700px) {
          height: 320px;
          width: 100%;
          margin: 20px 0;
        }
      }
    }

    .aboutUs_title {
      padding: 20px 0;
      text-align: center;
    }

    .aboutUs_title_lg {
      font-size: 1.2rem;
    }
  }

  .company {
    width: 1080px;
    margin: 0 auto;
    padding: 2rem 0;

    @media screen and (max-width: 700px) {
      width: 90%;
    }

    .title {
      text-align: center;

      > div {
        width: 100%;
      }
    }

    .contentTitle_lg {
      font-size: 1.2rem;
    }

    .card_Layout {
      margin-top: 60px;

      @media screen and (max-width: 700px) {
        margin-top: 20px;
      }
    }

    .company_des {
      padding: 1.5rem;
      font-size: 0.7rem;
    }

    .company_image {
      @media screen and (max-width: 700px) {
        height: 240px;
      }

      background: url(../asset/images/home/Introduction.png);
      background-size: 100% 100%;
    }
  }

  .content_des {
    width: 1280px;
    margin: 0 auto;
    padding: 2rem 0;

    @media screen and (max-width: 700px) {
      width: 90%;
    }

    .homepage_content {
      padding: 2rem 0;
    }

    .content_title {
      text-align: center;

      > div {
        width: 100%;
      }
    }

    .content_card {
      margin-top: 100px;
      box-shadow: rgba(gray, 0.2) 0 0 5px;
      background: #fff;

      @media screen and (max-width: 700px) {
        margin-top: 20px;
      }
    }

    .contentTitle_lg {
      font-size: 1.2rem;
    }

    .Message {
      background: url(../asset/images/home/Information_service.png);
      color: #fff;
    }

    .service_item {
      height: 280px;
      padding: 1rem;
      text-align: center;

      @media screen and (max-width: 700px) {
        height: 200px;
      }

      > div:nth-of-type(1) {
        padding: 0.4rem 0;
      }

      > div:nth-of-type(2) {
        font-size: 0.8rem;
        font-weight: bold;
        height: 1.5rem;
        line-height: 1.2;
      }

      > div:nth-of-type(3) {
        font-size: 0.6rem;
      }
    }
  }

  .company_idea {
    height: 760px;

    .company_idea_img {
      background-image: url(../asset/images/home/Self-motivated_forward.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      top: -50px;
    }

    .company_idea_content {
      position: absolute;
      padding: 1.5rem;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .company_idea_title {
      font-size: 1rem;
      font-weight: bold;
    }

    .company_idea_des {
      margin-top: 20px;
      font-size: 0.6rem;
    }
  }

  .emotional_connection {
    height: 720px;

    @media screen and (max-width: 700px) {
      height: 360px;
    }

    .emotional_leftCard {
      background: rgb(17, 149, 220);
      position: relative;
    }

    .emotional_rightCard_content {
      width: 70%;
      position: absolute;
      top: 40%;
      left: 0;
      display: flex;
      flex-direction: column;
      height: 400px;

      @media screen and (max-width: 700px) {
        height: 200px;
        width: 100%;
      }
      // overflow: hidden;
    }

    .emotional_rightCard_image {
      flex: 1;
      background-image: url(../asset/images/home/emotional_connection.png);
      background-size: 100% 100%;
    }

    .emotional_leftCard_content {
      position: absolute;
      padding: 1.5rem;
      top: 20%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      color: white;
      flex-direction: column;
      align-items: flex-start;
    }

    .emotional_leftCard_title {
      font-size: 1.6rem;

      @media screen and (max-width: 700px) {
        font-size: 1rem;
      }
    }
  }

  .IndustrySupport {
    height: 720px;

    @media screen and (max-width: 700px) {
      height: 360px;
    }
  }

  .IndustrySupport_rightCard {
    background: url(../asset/images/home/Industry_support.png);
    background-size: 100% 100%;
    position: relative;

    .IndustrySupport_rightCard_content {
      width: 500px;

      @media screen and (max-width: 700px) {
        width: 90%;
      }

      position: absolute;
      padding: 1.2rem;
      bottom: 0;
      color: #fff;
      left: 0;
    }

    .IndustrySupport_rightCard_title {
      font-size: 1rem;
      color: #fff;
      padding: 20px 0;
    }
  }

  .IndustrySupport_leftCard {
    position: relative;
    text-align: right;

    .IndustrySupport_leftCard_content {
      position: absolute;
      padding: 1.2rem;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.desIcon {
  width: 100%;
  height: 150px;
  padding: 0.5rem;
}

.cardDes {
  padding: 0.5rem;
}

#application {
  width: 1280px;
  margin: 40px auto 0 auto;
  font-family: "HarmonyOS_Medium", sans-serif;
  padding: 60px 0;

  @media screen and (max-width: 700px) {
    width: 90%;
  }

  .application_titleContent {
    padding: 30px 0;
    display: inline-block;
    text-align: right;
  }

  .application_title {
    font-size: 1rem;
  }

  .appCardContent {
    display: flex;
    gap: 36px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .appCard {
    border-radius: 15px;
    width: 340px;
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    @media screen and (max-width: 700px) {
      width: 100%;
    }

    &:hover {
      transform: scale(1.08, 1.08);
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      box-shadow: rgba(128, 128, 128, 0.2) 0 0 5px;
    }

    h1 {
      font-weight: 400;
      font-size: 0.9rem;
    }

    .AppDescription {
      font-size: 0.6rem;
      font-family: "HarmonyOS_Medium", sans-serif;
      color: gray;
    }

    .action {
      height: 60px;

      @include flex(flex-end, center);
    }

    .appCardImg {
      min-height: 100px;
    }
  }
}

.leftNode {
  padding: 120px 0 96px;
  width: 540px;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    width: unset;
    padding: 0 24px !important;
  }
}

.rightNode {
  overflow: hidden;
  width: 740px;
  height: 640px;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    float: unset;
    width: 100%;
    height: auto;
    margin: 0;
    overflow: hidden;
  }
}
