@import "./variables.scss";

@font-face {
  font-family: "HarmonyOS_Black";
  src: url("../asset/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Black.ttf");
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "HarmonyOS_light";
  src: url("../asset/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Light.ttf");
}

@font-face {
  font-family: "HarmonyOS_Thin";
  src: url("../asset/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Thin.ttf");
}

@font-face {
  font-family: "HarmonyOS_Medium";
  src: url("../asset/HarmonyOS_Sans_SC/HarmonyOS_Sans_SC_Medium.ttf");
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[type="pc"] {
  font-size: 170%;
}

html[type="book"] {
  font-size: 140%;
}

html[type="mobile"] {
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}

.ant-layout {
  min-height: 100vh;
  background-color: #f8f8f8 !important;
}

.ant-layout-footer {
  padding: 0;
}

.ant-layout-header {
  padding: 0 1.9rem;
}

.ant-menu-item {
  font-family: HarmonyOS_light, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: 600;
}

@media screen and (max-width: 700px) {
  .ant-row {
    margin-left: unset !important;
  }

  .ant-col {
    margin: 5px 0;
  }
}
.ant-layout-footer {
  .ant-descriptions-title {
    color: #fff;
  }

  .ant-descriptions-item-label {
    color: #fff;
  }

  .ant-descriptions-item-content {
    color: #fff;
  }
}

.ant-card-bordered .ant-card-cover {
  border-radius: inherit;
}

.ant-card-cover img {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.ant-image-mask {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
