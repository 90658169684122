
@import "./variables.scss";

.responseLayout {
  border-bottom: 1px solid rgba(128, 128, 128, 0.466);
  background-color: #fff;
  width: 100%;

  > div {
    width: 1280px;
    margin: 0 auto;
    height: 650px;

    @include flex(flex-start,center);

    @media screen and (max-width: 700px) {
      align-items: unset;
      height: unset;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      padding: 64px 0;
    }
  }
}

.Scroll {
  position: relative;

  @media screen and (max-width: 700px) {
    height: 2100px;
  }

  .scrollContent {
    left: 50%;
    transform: translateX(-50%);
    width: 1280px;

    @media screen and (max-width: 700px) {
      width: 300px;
    }

    gap: 20px;
    display: flex;

    .scroll-left {
      padding: 180px 0 96px;
      width: 630px;
      min-height: 630px;

      @media screen and (max-width: 700px) {
        text-align: center;
        width: 100%;
        min-height: unset;
        padding: 12px 0;
      }
    }

    .scroll-Right {
      flex: 1;
      width: 740px;

      @include flex(center,center);

      @media screen and (max-width: 700px) {
        text-align: center;
        width: 100%;
      }
    }

    @media screen and (max-width: 700px) {
      align-items: unset;
      height: unset;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      padding: 64px 0;
    }
  }
}

.leftNode {
  padding: 120px 0 96px;
  width: 540px;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    width: unset;
    padding: 0 24px !important;
  }
}

.rightNode {
  overflow: hidden;
  width: 740px;
  height: 640px;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    float: unset;
    width: 100%;
    height: auto;
    margin: 0;
    overflow: hidden;
  }
}

.shadow {
  @include flex(center, center);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  > div {
    width: 100%;
  }

  .video {
    width: 50%;
    height: 60%;
    object-fit: fill;

    @media screen and (max-width: 700px) {
      width: 80%;
      height: 30%;
    }
  }

  .closeIcon {
    padding: 1rem;
    display: block;
    font-size: 2rem;
    color: rgba(#fff, 0.8);

    &:hover {
      color: rgba(rgb(62, 155, 209), 0.8);
    }
  }

  background-color: rgba(map-get($map: $grayRange, $key: "9"), 0.6);
}
