
@import './variables.scss';

#CityPartner {
  width: 100%;

  .main {
    width: 100%;
    background-image: url(../asset/images/partner/city_banner.png);
    background-size: 100% 100%;
    height: calc(100vh - 5rem);
    text-align: center;
    font-family: "HarmonyOS_Medium", 'sans';

    .title {
      font-size: 1.7rem;
      color: #000;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 2.4rem;
      text-align: center;
      margin: 50px auto;
    }

    .main_des {
      font-size: 0.7rem;
    }

    @include flex(center,center);

    @media screen and (max-width: 700px) {
      height: calc(100vh - 20rem);
    }
  }
}

#process {
  width: 100%;
  margin-top: 80px;

  .commonTitle {
    padding: 20px 0;
    text-align: center;
  }

  .commonTitle_lg {
    font-size: 1.2rem;
  }

  .processContent {
    @include flex(flex-start,flex-start);

    min-height: 400px;
    background-image: url(../asset/images/partner/The-city_building.png);
    background-size: 100% 100%;
    position: relative;

    @media (max-width: 700px) {
      background-image: unset;
    }

    .processContent_main {
      width: 1280px;
      position: absolute;
      left: 50%;
      color: #fff;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        @media (max-width: 700px) {
          transform: rotate(90deg);
          margin: 30px 0;
          color: #000;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
        width: 100%;
        position: static;
        transform: translate(0);
        margin: 50px 0;
      }
    }

    .rocessContent_main_item {
      width: 142px;
      height: 142px;
      color: #fff;
      background-color: #1e1a1a96;
      border-radius: 90px;
      text-align: center;

      @include flex(center,center);
    }

    .stepItem {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#application {
  width: 1280px;
  margin: 0 auto;
  font-family: 'HarmonyOS_Medium', sans-serif;

  @media screen and (max-width: 700px) {
    width: 90%;
  }

  .application_titleContent {
    padding: 30px 0;
    display: inline-block;
    text-align: right;
  }

  .application_title {
    font-size: 1rem;
  }

  .appCardContent {
    display: flex;
    gap: 36px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }

  .appCard {
    border-radius: 15px;
    width: 340px;

    @media screen and (max-width: 700px) {
      width: 100%;

      &:hover {
        transform: scale(1, 1);
      }
    }

    &:hover {
      transform: scale(1.08, 1.08);
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      box-shadow: rgba(128, 128, 128, 0.2) 0 0 5px;
    }

    h1 {
      font-weight: 400;
      font-size: 0.9rem;
    }

    .AppDescription {
      font-size: 0.6rem;
      font-family: 'HarmonyOS_Medium', sans-serif;
      color: gray;
    }

    .action {
      height: 60px;

      @include flex(flex-end,center);
    }

    .appCardImg {
      min-height: 100px;
    }
  }
}
