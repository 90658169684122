/** color */
$red: (
  '1': #fff1f0,
  '7': #ffccc7,
);

$grayRange: (
  "1": #fff,
  "2":#fafafa,
  "3":#f5f5f5,
  "4":#f0f0f0,
  "5":#d9d9d9,
  "6": #bfbfbf,
  "7": #8c8c8c,
  "8": #595959,
  "9": #434343,
  "10": #262626,
  "11": #1f1f1f,
  "12": #141414,
  "13": #000,
);
$elseColour:(
  "01":#1890ff,
);

/** font-family */
$font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, 'Helvetica Neue', arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

/** font-size  */
$font-size: (
  "small": 12px,
  "normal": 18px,
  "large": 24px,
  "large-700":30px
);

/** layout */
$header-height: 64px;
$slider-width: 256px;

/** flex布局 */
@mixin flex($justify:space-between, $align: center, $wrap: nowrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

/** flex item */
@mixin flexItem($grow: 1,$shrink:1,$basic:100) {
  flex: $grow $shrink $basic;
}

/** 通用 border */
@mixin border($color) {
  border: 1px solid $color;
}

/**  通用手型 */
@mixin cursor($pointer: pointer) {
  cursor: $pointer;
}

/**
 通用动画效果
 */
@mixin animation() {
  -webkit-transition: all 0.3s, padding 0s;
  transition: all 0.3s, padding 0s;
}
