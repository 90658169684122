@import './variables.scss';

#Message {
  @include flex(center,center);

  background: url(../asset/images/journey.png);
  background-size: 100% 100%;
  height: calc(100vh - 5rem);

  @include flex(center,center);

  @media (max-width: 700px) {
    height: calc(100vh - 20rem);
  }

  .content {
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 2px;
    width: 80%;
    text-align: center;
  }
}

.List {
  width: 1280px;
  margin: 0 auto;
  padding: 80px 0 48px;
  text-align: center;

  @media (max-width: 700px) {
    width: 90%;
    margin: 0 auto;
    padding: 40px 0 24px;
  }

  .listItem_title {
    font-size: 0.8rem;
    color: rgba(17, 149, 220, 100);
    padding: 10px  0;
  }

  .listItem_img {
    @include flex(center,center);
  }

  .listItem {
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(17, 149, 220, 100);
    display: flex;

    @media (max-width: 700px) {
      padding: 10px;
      flex-direction: column;
    }

    > div:nth-of-type(1) {
      flex: 0 0 100px;
    }

    > div:nth-of-type(2) {
      flex: 1;
      text-align: left;
    }
  }
}
