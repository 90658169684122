@import "./variables.scss";

#applyWork {
  a,
  p,
  span,
  div {
    font-family: "HarmonyOS_Medium", "Gill Sans", sans-serif;
  }

  background-color: #f0f2f5;
  height: 800px;

  .headers {
    background-color: white;
    height: 2.9rem;
  }

  .go_back {
    color: inherit;
    position: relative;
    top: 50%;
    left: 16.5%;
    cursor: pointer;

    @media (max-width: 700px) {
      left: 5%;
      top: 25%;
    }
  }

  .content {
    width: 100%;
    height: calc(100vh - 2.9rem);
    padding: 3rem 0 1.5rem 0;
    background-color: #f0f2f5;

    .card {
      width: 1280px;
      margin: 0 auto;
      background-color: white;
      min-height: 600px;
      padding: 36px 36px 0 36px;
      position: relative;

      .btn {
        position: absolute;
        right: 80px;
        top: 92px;
        line-height: 33px;
        padding: 0 23px;
        background-color: rgb(34, 40, 49);
        color: white;

        @media (max-width: 700px) {
          bottom: 10%;
          right: unset;
          left: 36px;
          top: unset;
        }
      }

      @media (max-width: 700px) {
        width: 90%;
      }
    }
  }

  .job_info {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .job_info_top {
    display: flex;
    color: #89909e;
    font-size: 12px;
  }

  .job_title {
    margin-top: 6px;
    font-size: 20px;
    line-height: 28px;
    color: #222831;
    margin-right: 120px;
  }

  .status_item {
    display: inline-block;
    color: #89909e;
    margin-top: 5px;
    padding: 0 10px;
    border-right: 1px solid #dddfe3;

    &:first-child {
      padding-left: 0;
    }
  }

  .workAddress {
    flex-wrap: wrap;
    padding-right: 145px;
    margin-top: 6px;
    color: #89909e;
  }

  .workContent {
    font-size: 14px;
    margin-top: 5px;

    .workContentTitle {
      align-items: center;
      margin-bottom: 19px;
      padding-top: 10px;
      color: #222831;
      font-size: 16px;
    }
  }

  .hr {
    flex-grow: 1;
    height: 1px;
    background-color: #f4f4f5;
  }
}
